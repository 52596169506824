<template>
  <!--
    The view for the InstallationChangelogList-component.
    Displays all installation changelogs.
  -->
  <Portlet
    title="Changelogs"
    class="changelog"
    icon="clock"
  >
    <ChangelogList 
      ref="list"
    />
  </Portlet>
</template>

<script>
export default {
  name: "ChangelogsView",
  components: {
    ChangelogList: () => import('@/components/ChangelogList.vue')
  }
}
</script>